import styled from 'styled-components';
import media from 'styled-media-query';

export const FooterWrapper = styled.div`
  margin-top: 0rem;
`;

export const FooterContainer = styled.div`
  width: 100%;
  max-width: var(--width-container);
  margin: 0 auto;
  padding: calc(var(--space) * 1.3) var(--space);
  ${media.greaterThan('large')`
    padding: calc(var(--space)*1.3) var(--space-sm);
  `}

  p {
    margin: 1rem 0;
  }

  h1 {
    font-size: 4rem;
    font-weight: 600;
  }
  .section {
    margin-bottom: 50px;

  }
  .copyright {
    margin-top: 50px;
    color: #b0b0b0!important;
  }

`;
