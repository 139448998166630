import React from 'react';
import AppProvider from './src/hooks';
import { BaseLayout } from './src/layouts/BaseLayout';
import { HomeLayout } from './src/layouts/HomeLayout';

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
const wrapPageElement = ({ element, props }) => (
  <AppProvider>
      {
            props.location.pathname === "/" || props.location.pathname === "/fr" ? 
            <HomeLayout {...props}>{element}</HomeLayout> :
            <BaseLayout {...props}>{element}</BaseLayout>
      }
  </AppProvider>
);

export default wrapPageElement;
