import React from 'react';
import useTranslations from '../useTranslations';
import SocialLinks from '../SocialLinks';
import LocalizedLink from '../../components/LocalizedLink'

import * as S from './styled'

const Footer = () => {
    const {
        aboutProject,
        seeMorePWA,
        maintainedBy,
        contributeMessage,
    } = useTranslations()

    const year = new Date().getFullYear()
    return (
        <S.FooterWrapper>
            <S.FooterContainer>
                <div id="contact" className="section">
                    <h1>Hey, let's do it!</h1>
                    <p>
                        Do you have an awesome project? Don't be shy, contact
                        us.
                    </p>
                    <LocalizedLink to={`/wizard`}>
                        Let's get started!
                    </LocalizedLink>
                </div>

                <div className="section">
                    You can reach us also on:
                    <SocialLinks />
                </div>

                <div className="section copyright">
                    &copy; giogos {year} | All rights reserved.
                </div>
            </S.FooterContainer>
        </S.FooterWrapper>
    )
}

export default Footer;
