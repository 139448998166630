import React from 'react';

import { Instagram } from 'styled-icons/boxicons-logos/Instagram';
import { Twitter } from 'styled-icons/boxicons-logos/Twitter';
import { Envelope } from 'styled-icons/boxicons-regular/Envelope'

import * as S from './styled'

const SocialLinks = () => {
    return (
        <S.SocialLinksList>
            <S.SocialLinksItem>
                <S.SocialLinksLink
                    href="mailto:info@giogos.ch"
                    title="Giogos"
                    target="_blank"
                >
                    <Envelope />
                </S.SocialLinksLink>
            </S.SocialLinksItem>
            <S.SocialLinksItem>
                <S.SocialLinksLink
                    href="https://www.instagram.com/giogosch"
                    title="Instagram"
                    target="_blank"
                >
                    <Instagram />
                </S.SocialLinksLink>
            </S.SocialLinksItem>
            <S.SocialLinksItem>
                <S.SocialLinksLink
                    href="https://twitter.com/giogosch"
                    title="Twitter"
                    target="_blank"
                >
                    <Twitter />
                </S.SocialLinksLink>
            </S.SocialLinksItem>
        </S.SocialLinksList>
    )
}

export default SocialLinks;
