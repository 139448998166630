import { createGlobalStyle } from 'styled-components'
import media from 'styled-media-query'
import styled from 'styled-components'

export const Button = styled.button`
    /* Adapt the colors based on primary prop */
    background: red;
    color: white;
    font-size: 1em;
    margin: 1em;
    padding: 0.25em 1em;
    border: 2px solid palevioletred;
    border-radius: 3px;
`

const GlobalStyles = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */

  body {  }

  // html, body, div, span, applet, object, iframe,
  // h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  // a, abbr, acronym, address, big, cite, code,
  // del, dfn, em, img, ins, kbd, q, s, samp,
  // small, strike, strong, sub, sup, tt, var,
  // b, u, i, center,
  // dl, dt, dd, ol, ul, li,
  // fieldset, form, label, legend,
  // table, caption, tbody, tfoot, thead, tr, th, td,
  // article, aside, canvas, details, embed,
  // figure, figcaption, footer, header, hgroup,
  // menu, nav, output, ruby, section, summary,
  // time, mark, audio, video {
  //   margin: 0;
  //   padding: 0;
  //   border: 0;
  //   font-size: 100%;
  //   font: inherit;
  //   vertical-align: baseline;
  // }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  html {
    font-size: 62.5%;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  * {
    box-sizing: border-box;
  }
  body {
    background: var(--body-bg);
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    margin: 0;
    font-size: 1.5rem;
    line-height: 160%;
    ${media.greaterThan('large')`
      font-size: 1.7rem;
    `}
  }
  img {
    display: block;
  	max-width: 100%;
  	height: auto;
  }

  a {
    color: var(--primaryColor)
  }

  :root {
    --gray-extra-light: #eaeaea;
    --gray-light: #747d8d;
    --gray: #475060;
    --gray-dark: #2e333e;

    --primary-color: #000;
    --secondary-color: #2e333e;
    --thirdy-color: #333;

    --body-bg: #FFFFFF;

    --bg-light: var(--gray-extra-light);
    --bg-dark: var(--gray-dark);

    --border-light: var(--gray-light);
    --border-dark: var(--gray-dark);

    --link-color: var(--primary-color);
    --link-color-hover: var(--thirdy-color);

    --text-color: var(--gray);
    --text-light:var(--gray-light);
    --text-dark: var(--gray-dark);

    --width-container: 1040px;

    --space: 2rem;
    --space-sm: 1rem;
    --space-lg: 3rem;
  }

  .tag-cloud-tag {
    margin-bottom: 15px !important;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 15px!important;
    background-color: #fff;
    border: 1px solid #000;
    box-shadow: 3px 2px #000;
  }

  .container {
    width: 100%;
    }

    .row {
      display: flex;
      width: 100%;
    }

    .row div {
      flex-grow: 1;
      flex-basis: calc(100% / 2);
    }
`
export default GlobalStyles
